










































































import { Component, PropSync, Mixins, Prop } from "vue-property-decorator";
import User from "@/entity/User";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import RulesMixin from "@/mixins/RulesMixin";
import { Getter, State } from "vuex-class";

@Component
export default class ConsultantFormBasicInfo extends Mixins(
  TranslatedEnumListsMixin,
  RulesMixin
) {
  @Prop() entityId!: number | null;
  @PropSync("value") user!: User;

  @State("user", { namespace: "auth" }) loggedInUser!: User;
  @Getter("isAdmin", { namespace: "auth" }) isLoggedInAdmin!: boolean;

  get canEditEnabledAndRole(): boolean {
    return !(this.entityId === this.loggedInUser.id);
  }

  handleEnabledChange(value: boolean): void {
    this.user.enabled = value;
  }
}
