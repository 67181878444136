



















































import { Component, Mixins } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader, TableQuery } from "@/components/data-table/types";
import ConsultantFormCard from "@/components/consultant-form/ConsultantFormCard.vue";
import UserService from "@/services/UserService";
import User from "@/entity/User";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DialogMixin from "@/mixins/DialogMixin";
import Link from "@/components/common/Link.vue";
import { LinkType } from "@/enums";

@Component({
  components: {
    ConsultantFormCard,
    DataTable,
    Link,
  },
})
export default class ConsultantsView extends Mixins(
  TranslatedEnumListsMixin,
  DialogMixin
) {
  isTableLoading = false;
  itemsLength = 0;
  consultants: User[] = [];
  options!: TableQuery;

  LinkType = LinkType;

  get headers(): ITableHeader[] {
    return [
      {
        filterType: "text",
        value: "username",
        text: this.$tc("username"),
        width: "150px",
      },
      {
        filterType: "text",
        value: "displayName",
        text: this.$tc("displayName"),
        width: "150px",
      },
      {
        filterType: "text",
        value: "email",
        text: this.$tc("email"),
        width: "200px",
      },
      {
        filterType: "text",
        value: "phone",
        text: this.$tc("phone"),
        width: "150px",
      },
      {
        options: this.translatedEnums.boolOptions,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        value: "enabled",
        text: this.$tc("enabled"),
        width: "150px",
      },
      {
        options: this.translatedEnums.userRoles,
        optionText: "name",
        optionValue: "value",
        filterType: "select",
        value: "role",
        text: this.$tc("role", 1),
        width: "150px",
      },
    ];
  }

  handleFormSuccess() {
    this.closeDialog();
    this.fetchConsultants();
  }

  async fetchConsultants() {
    try {
      this.isTableLoading = true;
      const response = await UserService.find(this.options);
      this.consultants = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isTableLoading = false;
    }
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchConsultants();
  }
}
