var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"name":"consultantsOverview","loading":_vm.isTableLoading,"items":_vm.consultants,"items-length":_vm.itemsLength,"headers":_vm.headers,"clickable":true,"has-add-button":true,"add-button-text":_vm.$tc('addConsultant')},on:{"optionsChanged":_vm.onOptionsChange,"rowClicked":function($event){return _vm.openDialog('consultantForm', $event.id)},"addItemClicked":function($event){return _vm.openDialog('consultantForm')}},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("booleanTextValue")(item.enabled))+" ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$tc(item.role.toLowerCase(), 1))+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('Link',{attrs:{"href":item.email,"prefix":_vm.LinkType.MAIL}}):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?_c('Link',{attrs:{"href":item.phone,"prefix":_vm.LinkType.TELEPHONE}}):_vm._e()]}}])}),_c('DialogWrapper',{attrs:{"value":_vm.isDialogOpened},on:{"close":_vm.closeDialog}},[(_vm.activeDialog === 'consultantForm')?_c('ConsultantFormCard',{attrs:{"entity-id":_vm.dialogEntityId},on:{"cancel":_vm.closeDialog,"success":_vm.handleFormSuccess}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }